/* eslint-disable no-unused-vars */
import axios from 'axios';
import { openLoginPopup } from '../redux/actions/authActions';
// import { JSEncrypt } from "jsencrypt";

// const baseUrl = process.env.REACT_APP_API_URL;

// async function getAccessToken() {
//   const publicKey = `-----BEGIN PUBLIC KEY-----\n
//   MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA85wPBOipOjFENlR0S3XE\n
//   fZr9LKjYVG6jlY6bGx9nN8+TZBGniBJFYSGxf9At04y3ANR00znDEJeqoBh3TV4y\n
//   yVzK198W7Tg7Ep+x/5vLQOXqrWst1gHutwH3UTVmIPk9XNXBYY/El44/ZfrmsTg4\n
//   2MHNnPVyWlvSkB1dyk/k6BdpT0VVHvc23VvTzFi/yv7xyCv8PFQ93UzMpKW4E5Ib\n
//   MlqoJexMmB+uu5x53yOwMJTvDSKWRJ0C9cSdHCyZzXLHGhmiiOyvXsEc3WSFQYOx\n
//   X/skXZouiz8q/HotX5U5JWed+zfNmHrjDzndgbPCvSgROK3cuyDvoGgIG01HrJ3Q\n
//   fQIDAQAB\n
//   -----END PUBLIC KEY-----`

//   let response = await axios.post(baseUrl + "app/bt4857896")

//   if (response.status == 200) {
//     const accessToken = response.data.accessToken; // Assuming the access token is in the response data

//     let encrypter = new JSEncrypt();
//     encrypter.setPublicKey(publicKey);

//     // Perform our encryption based on our public key - only private key can read it!
//     let encrypted = encrypter.encrypt(accessToken, true);

//     return encrypted;
//   } else return false

// }

axios.interceptors.request.use(function (config) {
  if (window.localStorage.getItem('authToken')) {
    config.headers.Authorization = `Bearer ${window.localStorage.getItem('authToken')}`;
  }
  config.headers.Language = window.localStorage.getItem('i18nextLng');
  return config;
});

export async function Postdata(url, payload, dispatch, header) {
  // const token = await getAccessToken();

  if (!header) header = {};
  if (!("headers" in header)) {
    header = Object.assign({
      headers: {
        'Content-Type': 'multipart/form-data',
        // "X-REQUEST-TOKEN": token
      }
    }, header);
  }

  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, header)
      .then((response) => {
        if (response.data.message === "Invalid token, Please try again.") {
          dispatch && dispatch(openLoginPopup());
          response.data.message = 'Login is required.'
        }
        resolve(response.data);
      })
      .catch((e) => {
        e.message = (e.message == 'Network Error') ? 'Please check your internet connection and try again...' : e.message;
        reject(e);
      });
  });
}

export async function Get(url, dispatch) {
  // const token = await getAccessToken();

  let header = {};
  if (!("headers" in header)) {
    header = Object.assign({
      headers: {
        'Content-Type': 'multipart/form-data',
       // "X-REQUEST-TOKEN": token
      }
    }, header);
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, header)
      .then((response) => {
        resolve(response.data);
        if (response.data.message === "Invalid token, Please try again.") {
          dispatch && dispatch(openLoginPopup());
        }
      })
      .catch((e) => {
        e.message = (e.message == 'Network Error') ? 'Please check your internet connection and try again...' : e.message;
        reject(e);
      });
  });
}