import React from "react";

import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <>
      <div className="menuPanel">
        <section className="navMenu">
          <section className="menuWrapper">
            {/* <h6>Popular <i className="fireicon"></i></h6> */}
            <nav>
              <ul>
              <li>
                  <Link to="/Home">
                    <i className="home"></i>
                    <span>Home</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/comparitive-analysis">
                    <i className="comparitiveAnalysis"></i>
                    <span>Comparitive Analysis</span>
                  </Link>
                </li> */}

                {/* <li>
                  <Link to="/daily-rewards">
                    <i className="dailyRewards"></i>
                    <span>Daily Rewards</span>
                  </Link>
                </li> */}

                <li>
                  <Link to="/in-play">
                    <i className="inPlay"></i>
                    <span>In Play</span>
                  </Link>
                </li>

                <li>
                  <Link to="/live-bet">
                    <i className="liveBets"></i>
                    <span>Live Bets</span>
                  </Link>
                </li>

                {/* <li>
                                    <a href="">
                                        <i className="election"></i>
                                        <span>Fantasy</span>
                                    </a>
                                </li> */}

                <li>
                  <Link to="/cricket">
                    <i className="cricket"></i>
                    <span>Cricket</span>
                  </Link>
                </li>

                <li>
                  <Link to="/soccer">
                    <i className="soccer"></i>
                    <span>Soccer</span>
                  </Link>
                </li>

                <li>
                  <Link to="/tennis">
                    <i className="tennis"></i>
                    <span>Tennis</span>
                  </Link>
                </li>
{/* 
                <li>
                  <Link to="/hockey">
                    <i className="hockey"></i>
                    <span>Hockey</span>
                  </Link>
                </li> */}

                <li>
                  <Link to="/election">
                    <i className="election"></i>
                    <span>Election</span>
                  </Link>
                </li>

                <li>
                  <Link to="/casino">
                    <i className="casino"></i>
                    <span>Casino Games</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </section>
        </section>
        {/* <div className="overlay" id="menuOverlay" style="display:none;" onClick="openMenu()"></div> */}
      </div>
    </>
  );
}

export default Sidebar;
