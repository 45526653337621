/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import HttpService from '../../utils/http.service'
import { Postdata } from '../../utils/api.service'
import Alert from '../common/Alert'

function Withdraw_Money({ setWithDrawModal }) {
    const [loading, setLoading] = useState(false)
    const [coins, setCoins] = useState(0)
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const onWithdrawRequest = (e) => {
        e.preventDefault()
        setMessage('')
        setErrorMessage('')
        setLoading(true)
        Postdata(HttpService.Coins_withdraw, { coins, type: 'bank' }).then((e) => {
            if (e.status == 200) {
                setMessage(e.message)
            } else {
                setErrorMessage(e.message)
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    return (
        <>
            {message.length ?
                <Alert
                    message={message}
                    variant="success"
                />
                : ''}

            {errorMessage.length ?
                <Alert
                    message={errorMessage}
                    variant="error"
                />
                : ''}

            <h4>Withdraw Money</h4>
            <div className="modelContent">
                <form onSubmit={onWithdrawRequest}>
                    <div className="amountAdd">
                        <label>Amount to Withdraw</label>
                        <input
                            type='number'
                            min={0}
                            placeholder='0'
                            value={coins}
                            onChange={(e) => {
                                setCoins(e.target.value)
                            }}
                        />
                    </div>

                    <div className="submitBtnDiv" style={{ display: "block", textAlign: "center" }}>
                        <span className="submitBtn skyBlue" style={{ width: "50%", textAlign: "center" }}>
                            {loading ? <span className="spinLoader small"></span> : <button className="btn" type='submit' >Submit</button>}
                        </span>
                    </div>
                </form>

                <div className="bottomLink">
                    <div><Link to="/account-statement" onClick={() => {
                        setWithDrawModal(false)
                    }}>Transaction History</Link></div>
                </div>
            </div>
        </>
    )
}

export default Withdraw_Money