/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React from 'react'
import { useState, useEffect } from 'react'
import Marquee from "react-fast-marquee";

import Logo from '../../assets/logo.png'
import AddIcon from '../../assets/add-icon.svg'
import NotificationIcon from '../../assets/notification.svg'
import ArrowDownIcon from '../../assets/down-arrow.svg'
import DefaultUserIcon from '../../assets/default_user_avtar.jpg'
import NotificationInsideIcon from '../../assets/notification_icon.svg'

import LoginModal from '../modal/Login'
// import RegisterModal from '../modal/Register'
import BalanceModal from '../modal/Balance'
import AddMoneyModal from '../modal/Add_Money'
import WithdrawMoneyModal from '../modal/Withdraw_Money'
import ForgotPasswordModal from '../modal/Forgot_Password'
import LiveBetsModal from '../modal/Live_Bets'

import BetHistoryIcon from '../../assets/bet-history.svg'
import BetStakeSettingIcon from '../../assets/bet-stake-setting.svg'
import PrivateBetIcon from '../../assets/private-bet.svg'
import AccountStatementIcon from '../../assets/account-statement.svg'
import ProfitLossIcon from '../../assets/total-profit-loss.svg'
import CasinoBetHistory from '../../assets/casino-bet-history.svg'
import FavrtIcon from '../../assets/favourites.svg'
import ChangePassword from '../../assets/change-password.svg'

import ModalPopup from './Modal'
import { useDispatch, useSelector } from 'react-redux'
import { loginClear, verifyToken } from "../../redux/actions/authActions";
import { isError, isLoading, isAuthenticated, isData } from "../../redux/selectors/authSelector"
import { useLocation, useNavigate, Link } from 'react-router-dom';

import HttpService from '../../utils/http.service'
import { Postdata, Get } from '../../utils/api.service'

import { ConvertDate, ConvertOnlyTime, numberWithCommas } from './Helper'

// import Sidebar from './Sidebar'
// import Fantasy_Sidebar from './Fantasy_Sidebar'

function Header() {
	const dispatch = useDispatch();

	const currentPage = window.location.pathname

	const { state } = useLocation();
	const navigate = useNavigate()

	const errorMsg = useSelector(isError);
	const Authenticated = useSelector(isAuthenticated);
	const loader = useSelector(isLoading);

	const profileData = useSelector(isData)
	const [isMenu, setIsMenu] = useState(false)
	const [profileMenu, setProfileMenu] = useState(false)

	const [loginModal, setLoginModal] = useState(false)
	// const [registerModal, setRegisterModal] = useState(false)
	const [balanceModal, setBalanceModal] = useState(false)
	const [addMoneyModal, setAddMoneyModal] = useState(false)
	const [withdrawModal, setWithDrawModal] = useState(false)
	const [forgotPasswordModal, setForgotPasswordModal] = useState(false)
	const [liveBetsModal, setLiveBetsModal] = useState(false)

	const [openNotification, setOpenNotification] = useState(false)
	// const [notifications, setNotifications] = useState([])

	// const [upcomingData, setUpcomingData] = useState([])

	useEffect(() => {
		if (Authenticated) {
			// setRegisterModal(false)
			setLoginModal(false)
		}
	}, [Authenticated]);

	// useEffect(() => {
	// 	if (Authenticated) {
	// 		Postdata(HttpService.notificationList, {}).then((e) => {
	// 			// console.log(e)
	// 			if (e.status == 200) {
	// 				setNotifications(e.data.records)
	// 			}
	// 		}).catch((err) => {
	// 			console.log(err)
	// 		});
	// 	}
	// }, [Authenticated]);


	useEffect(() => {
		if (state?.login) {
			setLoginModal(true)
			navigate(location.pathname, {});
		}
	}, [state]);

	useEffect(() => {
		if (!Authenticated && localStorage.getItem('authToken')) {
			dispatch(verifyToken());
		}
		// if (!localStorage.getItem('authToken') && window.location.hash) registerModal();
	}, [Authenticated]);

	const LogoutCallApi = () => {
		dispatch(loginClear());
		return false;
	};

	// useEffect(() => {
	// 	Get(HttpService.upcomingMatch).then((e) => {
	// 		console.log(e)
	// 		if (e.status == 200) {
	// 			setUpcomingData(e.data)
	// 		}
	// 	}).catch((err) => {
	// 		console.log(err)
	// 	});
	// }, [])

	return (
		<>
			{!Authenticated ?
				<>
					{/* ======= Login Modal ==========  */}
					<ModalPopup
						show={loginModal}
						onHide={setLoginModal}
						className="signIn"
					>
						<LoginModal
							setLoginModal={setLoginModal}
							// setRegisterModal={setRegisterModal}
							setForgotPasswordModal={setForgotPasswordModal}
						/>
					</ModalPopup>

					{/* ============= Signup Modal =========  */}
					{/* <ModalPopup
						show={registerModal}
						onHide={setRegisterModal}
						className="signIn signUp"
					>
						<RegisterModal
							setLoginModal={setLoginModal}
							setRegisterModal={setRegisterModal}
							setForgotPasswordModal={setForgotPasswordModal}
						/>
					</ModalPopup> */}

					{/* Forgot Password Modal  */}
					<ModalPopup
						show={forgotPasswordModal}
						onHide={setForgotPasswordModal}
						className="signIn"
					>
						<ForgotPasswordModal
							setLoginModal={setLoginModal}
							// setRegisterModal={setRegisterModal}
							setForgotPasswordModal={setForgotPasswordModal}
						/>
					</ModalPopup>
				</>
				: ''}


			{/* ========= Header Start =============  */}
			<header className="headerMain">
				<div className="headerTop">
					<a href="javascript:;" className="menuIco" onClick={() => setIsMenu(!isMenu)}></a>

					<div className="logo">
						<Link to="/">
							<img src={Logo} />
						</Link>
					</div>

					{Authenticated ?
						<>

							{/* ======== Balance Modal ============== */}
							<ModalPopup
								show={balanceModal}
								onHide={setBalanceModal}
								className="balanceDetails"
								size="850px"
							>
								<BalanceModal
									setBalanceModal={setBalanceModal}
									setAddMoneyModal={setAddMoneyModal}
									setWithDrawModal={setWithDrawModal}
								/>
							</ModalPopup>

							{/* ======== Add Money Modal ============== */}
							<ModalPopup
								show={addMoneyModal}
								onHide={setAddMoneyModal}
								className="addMoney"
								size="850px"
							>
								<AddMoneyModal
									setAddMoneyModal={setAddMoneyModal}
								/>
							</ModalPopup>

							{/* ======== Withdraw Money Modal ============== */}
							<ModalPopup
								show={withdrawModal}
								onHide={setWithDrawModal}
								className="addMoney"
								size="850px"
							>
								<WithdrawMoneyModal
									setWithDrawModal={setWithDrawModal}
								/>
							</ModalPopup>

							{/* ======== Live Bets Modal ============== */}
							<ModalPopup
								show={liveBetsModal}
								onHide={setLiveBetsModal}
								size="850px"
							>
								<LiveBetsModal
									show={liveBetsModal}
								/>
							</ModalPopup>

							<div className="userInfo">
								<div className="box cursor-pointer">
									<div>
										<label>Balance</label>
										<span>{numberWithCommas(profileData.user.usable_coin_balance + profileData.user.current_liability)}</span>
									</div>
									{/* <div className="addIcon" onClick={() => setBalanceModal(true)}><img src={AddIcon} /></div> */}
								</div>

								<div className="box cursor-pointer" onClick={() => setLiveBetsModal(true)}>
									<div>
										<label>Exposure</label>
										<span>{numberWithCommas(profileData.user.current_liability)}</span>
										{/* <span>{numberWithCommas(profileData.user.exposore)}</span> */}
									</div>
								</div>
								<div className="notificationIcon"><a><img src={NotificationIcon} onClick={() => setOpenNotification(!openNotification)} /></a></div>
								<div className="loggedInUser" onClick={() => setProfileMenu(true)}>
									<span>
										<img src={profileData.user.profile_pic} />
									</span>
									<label>{profileData.user.username}
										<img src={ArrowDownIcon} style={{ maxWidth: '20px', top: '6px', marginLeft: '5px' }} />
										{/* <select className="selectDropDown">
										<option>My Profile</option>
									</select> */}
									</label>
								</div>
							</div>
						</>
						:
						<>
							{/* <nav className="menu">
								<ul>
									<li><Link to="/home" className={currentPage == '/home' ? "active" : ''}>Home</Link></li>
									<li><Link className={currentPage == '/in-play' ? "active" : ''} to='/in-play'>In Play</Link></li>
									<li><Link className={currentPage == '/cricket' ? "active" : ''} to='/cricket'>Cricket</Link></li>
									<li><Link className={currentPage == '/soccer' ? "active" : ''} to='/soccer'>Soccer</Link></li>
									<li><Link className={currentPage == '/tennis' ? "active" : ''} to='/tennis'>Tennis</Link></li>
									<li><Link className={currentPage == '/election' ? "active" : ''} to='/election'>Election</Link></li>
									<li><Link className={currentPage == '/horse-racing' ? "active" : ''} to='/horse-racing'>Horse Racing</Link></li>
									<li><Link className={currentPage == '/casino' ? "active" : ''} to='/casino'>Casino Games</Link></li>
								</ul>
							</nav> */}
							<div className="loginLink">
								<span className="button">
									<div className="btnLoader" style={{ display: "none" }}>
										<span className="spinLoader small"></span>
									</div>
									<a href="javascript:;" onClick={() => {
										setLoginModal(true)
									}}>Log In</a>
								</span>

								{/* <span className="button white">
								<div className="btnLoader" style={{ display: "none" }}>
									<span className="spinLoader small"></span>
								</div>
								<a href="javascript:;" onClick={() => setRegisterModal(true)}>Sign Up</a>
							</span> */}
							</div></>}
				</div>
				{/* {upcomingData.length > 0 ?
					<div className="sportMarquee">
						<div className="sportMarqueeTitle">Upcoming <span>Fixture</span></div>
						<div className="sportMarqueeWrap">
							<Marquee pauseOnHover>
								{upcomingData.map((i, index) => {
									// if (i.event_status == 'PREMATCH') {
									// 	return false
									// } else {
									return <div key={index} className="marqueeText"><Link to={`match-details/${i.eventId}`}>{i.name} <span>({ConvertDate(i.startTime)} - {ConvertOnlyTime(i.startTime)})</span></Link></div>
									// }
								})}
							</Marquee>
						</div>
					</div>
					: <div className="sportMarquee">
						<div className="sportMarqueeTitle">Upcoming <span>Fixere</span></div>
						<div className="sportMarqueeWrap">
							<Marquee pauseOnHover>
								<div className="marqueeText">No upcoming match...</div>
							</Marquee>
						</div>
					</div>} */}

				<a href="javascript:;" className="menuIcon" style={{ display: "none" }}></a>



				{/* <!-- top header end here --> */}
				{/* <div className="headerBottom">
					<nav className="menu">
						<ul>
							<li><Link to="/home" className={currentPage == '/home' ? "active" : ''}>Home</Link></li>
							<li><Link className={currentPage == '/in-play' ? "active" : ''} to='/in-play'>In Play</Link></li>
							<li><Link className={currentPage == '/cricket' ? "active" : ''} to='/cricket'>Cricket</Link></li>
							<li><Link className={currentPage == '/soccer' ? "active" : ''} to='/soccer'>Soccer</Link></li>
							<li><Link className={currentPage == '/tennis' ? "active" : ''} to='/tennis'>Tennis</Link></li>
							<li><Link className={currentPage == '/hockey' ? "active" : ''} to='/hockey'>Hockey</Link></li>
							<li><Link className={currentPage == '/election' ? "active" : ''} to='/election'>Election</Link></li>
							<li><Link className={currentPage == '/horse-racing' ? "active" : ''} to='/horse-racing'>Horse Racing</Link></li>
							<li><Link className={currentPage == '/casino' ? "active" : ''} to='/casino'>Casino Games</Link></li>
							<li><a href="">Casino Games</a></li>
						</ul>
					</nav>
				</div> */}
				{/* <!-- bottom header end here -->	 */}
			</header>

			<div className={profileMenu ? "menuOverlay-active" : "menuOverlay"} id="menuOverlay" onClick={() => setProfileMenu(false)} ></div>
			<div id="profileMenu" className={profileMenu ? 'menuPanel profileMenu' : "menuPanel"} style={{ display: profileMenu ? "block" : "none" }}>
				<a href="javascript:;" className="close" onClick={() => setProfileMenu(false)}></a>
				<section className="menuWrapper " onClick={() => setProfileMenu(false)}>
					<ul>
						{/* <li>
							<Link to="/profile">
								<i className="comparitiveAnalysis">
									<img src={BetHistoryIcon} />
								</i>
								<span>My Profile</span>
							</Link>
						</li> */}
						<li>
							<Link to="/unsettled-bets">
								<i className="liveBets">
									<img src={AccountStatementIcon} />
								</i>
								<span>Unsettled Bets</span>
							</Link>
						</li>
						<li>
							<Link to="/bet-stake-setting">
								<i className="dailyRewards">
									<img src={BetStakeSettingIcon} />
								</i>
								<span>Bet Stake Setting</span>
							</Link>
						</li>
						{/* <li>
							<Link to="/private-bet">
								<i className="inPlay">
									<img src={PrivateBetIcon} />
								</i>
								<span>Private Bet</span>
							</Link>
						</li> */}
						<li>
							<Link to="/account-statement">
								<i className="liveBets">
									<img src={AccountStatementIcon} />
								</i>
								<span>Account Statement</span>
							</Link>
						</li>
						<li>
							<Link to="/profit-loss">
								<i className="election">
									<img src={ProfitLossIcon} />
								</i>
								<span>Total Profit & Loss</span>
							</Link>
						</li>
						<li>
							<Link to="/bet-history">
								<i className="cricket">
									<img src={BetHistoryIcon} />
								</i>
								<span>Bet History</span>
							</Link>
						</li>
						<li>
							<Link to="/casino-bet-history">
								<i className="soccer">
									<img src={CasinoBetHistory} />
								</i>
								<span>Casino Bet History</span>
							</Link>
						</li>
						<li>
							<Link to="/favourites">
								<i className="tennis">
									<img src={FavrtIcon} />
								</i>
								<span>Favourites</span>
							</Link>
						</li>
						<li>
							<Link to="/change-password">
								<i className="hockey">
									<img src={ChangePassword} />
								</i>
								<span>Change Password</span>
							</Link>
						</li>
						<li>
							<a href="" onClick={LogoutCallApi}>
								<i className="election">
									<img src={BetHistoryIcon} />
								</i>
								<span>Logout</span>
							</a>
						</li>
					</ul>
				</section>
			</div>

			<div className={isMenu ? "menuOverlay-active" : "menuOverlay"} id="menuOverlay" onClick={() => setIsMenu(false)} ></div>
			<section className={isMenu ? "slideView-active activeView" : "slideView"} id="menu">
				<div className="menuBanner">
					<a href="" className="logo">
						<img src={Logo} alt="shivay365" />
					</a>
				</div>
				<ul onClick={() => setIsMenu(false)}>
					<li><Link to="/home" className={currentPage == '/home' ? "active" : ''}>Home</Link></li>
					{/* <li><Link className={currentPage == '/comparitive-analysis' ? "active" : ''} to='/comparitive-analysis'>Comparitive Analysis</Link></li> */}
					<li><Link className={currentPage == '/in-play' ? "active" : ''} to='/in-play'>In Play</Link></li>
					<li><Link className={currentPage == '/live-bet' ? "active" : ''} to='/live-bet'>Live Bets</Link></li>
					<li><Link className={currentPage == '/cricket' ? "active" : ''} to='/cricket'>Cricket</Link></li>
					<li><Link className={currentPage == '/soccer' ? "active" : ''} to='/soccer'>Soccer</Link></li>
					<li><Link className={currentPage == '/tennis' ? "active" : ''} to='/tennis'>Tennis</Link></li>
					{/* <li><Link className={currentPage == '/hockey' ? "active" : ''} to='/hockey'>Hockey</Link></li> */}
					<li><Link className={currentPage == '/election' ? "active" : ''} to='/election'>Election</Link></li>
					<li><Link className={currentPage == '/horse-racing' ? "active" : ''} to='/horse-racing'>Horse Racing</Link></li>
					<li><Link className={currentPage == '/casino' ? "active" : ''} to='/casino'>Casino Games</Link></li>
					{/* <li><Link className={currentPage == '/daily-rewards' ? "active" : ''} to='/daily-rewards'>Daily Rewards</Link></li> */}
				</ul>
				<div className="clr"></div>
			</section>

			{/* {Authenticated ? currentPage.includes("fantasy") ? <Fantasy_Sidebar /> : <Sidebar /> : ''} */}

		</>
	)
}

export default Header