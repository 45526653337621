/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-unreachable */
// import i18next from "i18next";
import './styles/Index.scss';
import DefaultRoutes from "./routes/routes";
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isAuthenticated } from './redux/selectors/authSelector';
import {BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    console.log('Something went wrong', error);
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return <h1>Oops, something went wrong...</h1>;
    }
    return this.props.children;
  }
}

function App() {
  const Authenticated = useSelector(isAuthenticated)
  
  return (
    <ErrorBoundary>  
       <Router>
      <div className="App">
      <ToastContainer
       position="top-right"
       autoClose={5000}
       hideProgressBar={false}
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover={true}
       theme="colored"
     
       ></ToastContainer>
        <DefaultRoutes />
      </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
