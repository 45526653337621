import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { pagetop } from "./Helper";

function ScrollToTop() {
  const { pathname } = useLocation();

  const scrollTop = () => {
    pagetop();
  }

  useEffect(() => {
    scrollTop();
  }, [pathname]);

  return null;
}

export default ScrollToTop;