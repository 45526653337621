import { SET_CASINO_CATEGORY, SET_CASINO_PROVIDER, SET_CASINO_GAMES, SET_CASINO_FAV_GAMES } from "../constants/constants";

const initialState = {
        casino_category:[],
        casino_provider:[],
        casino_games:[],
        casino_fav_games:[]
  };

export default (state = initialState, action) => {
   
    switch (action.type) {
      case SET_CASINO_CATEGORY:
        return { ...state, casino_category: action.data };
        case SET_CASINO_PROVIDER:
            return { ...state, casino_provider: action.data };
            case SET_CASINO_GAMES:
                return { ...state, casino_games: action.data };
            case SET_CASINO_FAV_GAMES:
                return { ...state, casino_fav_games: action.data };
      default:
        return state;
    }
  }
  