import * as types from "../constants/constants";
import { Postdata } from "../../utils/api.service"
import HttpService from "../../utils/http.service"

export const verifyToken = () => {
  return (dispatch) => {
    try {
      Postdata(HttpService.verifyTokenUrl, { token: localStorage.getItem('authToken') }, dispatch).then(
        (result) => {
          if (result.status === 200) {
            dispatch(loginSuccess(result));
            // dispatch(loginMessage());
          } else {
            dispatch(loginFail(result.message));
            dispatch(loginForce());
          }
        },
        (e) => {
          dispatch(loginFail(e.message));
          dispatch(loginForce());
        }
      );
    } catch (error) {
      dispatch(loginFail(error.message));
      dispatch(loginForce());
    }
  };
};

export const register = (data) => {
  return (dispatch) => {
    try {
      dispatch(loginInit());
      Postdata(HttpService.registerUrl, data, dispatch).then(
        (result) => {
          if (result.status === 200) {
            dispatch(loginSuccess(result));
          } else {
            dispatch(loginFail(result.message));
          }
        },
        (e) => {
          dispatch(loginFail(e.message));
        }
      );
    } catch (error) {
      dispatch(loginFail(error.message));
    }
  };
};

export const login = (data) => {
  return (dispatch) => {
    try {
      dispatch(loginInit());
      Postdata(HttpService.loginUrl, data, dispatch).then(
        (result) => {
          if (result.status === 200) {
            window.localStorage.setItem('remember', data.remember);
            if (data.remember) {
              window.localStorage.setItem('username', data.username);
              window.localStorage.setItem('password', data.password);
            } else {
              window.localStorage.setItem('username', '');
              window.localStorage.setItem('password', '');
            }
            dispatch(loginSuccess(result));
          } else {
            dispatch(loginFail(result.message));
          }
        },
        (e) => {
          dispatch(loginFail(e.message));
        }
      );
    } catch (error) {
      dispatch(loginFail(error.message));
    }
  };
};

export const updateProfile = (data) => {
  return (dispatch) => {
    try {
      dispatch(loginMessage());
      dispatch(loginLoading());
      Postdata(HttpService.updateProfileUrl, data, dispatch).then(
        (result) => {
          if (result.status === 200) {
            dispatch(updateSuccess(result));
          } else {
            dispatch(updateError(result.message));
          }
        },
        (e) => {
          dispatch(updateError(e.message));
        }
      );
    } catch (error) {
      dispatch(updateError(error.message));
    }
  };
};

export const loginInit = (data) => {
  return { type: types.LOGIN_DATA, data };
};

export const loginSuccess = (data) => {
  window.localStorage.setItem('authToken', data.data.token);
  return { type: types.LOGIN_DATA_SUCCESS, data };
};

export const updateSuccess = (data) => {
  return { type: types.LOGIN_UPDATE_SUCCESS, data };
};

export const loginFail = (data) => {
  window.localStorage.removeItem('authToken');
  return { type: types.LOGIN_DATA_FAIL, data };
};

export const loginClear = (data) => {
  if (window.localStorage.getItem('authToken')) {
    Postdata(HttpService.logoutUrl, { t: Date.now() });
  }
  window.localStorage.removeItem('authToken');
  return { type: types.LOGIN_DATA_CLEAR, data };
};

export const loginForce = () => {
  window.localStorage.removeItem('authToken');
  return window.location.href = '/';
};

export const loginMessage = () => {
  return { type: types.LOGIN_MSG_CLEAR };
};

export const loginLoading = () => {
  return { type: types.LOGIN_LOADER };
};

export const updateError = (data) => {
  return { type: types.LOGIN_MSG_ERROR, data };
};

export const friendsRefresh = () => {
  return { type: types.REFRESH_FRIEND };
};

export const openLoginPopup = () => {
  window.localStorage.removeItem('authToken');
  return { type: types.OPEN_LOGIN_POPUP };
};

export const becomeAgent = () => {
  return { type: types.BECOME_AGENT };
};

export const dontShowTerms = () => {
  return { type : types.DONT_SHOW_TERMS }
}