/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'

import EyeActiveIcon from '../../assets/eye_active.png'
import EyeIcon from '../../assets/eye.svg'
import Logo from '../../assets/logo.png'

import { useDispatch, useSelector } from 'react-redux';
import { isLoading, isError } from '../../redux/selectors/authSelector';
import { login } from '../../redux/actions/authActions';
import { useEffect } from 'react';

import Alert from '../common/Alert';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Login({ setLoginModal, setRegisterModal, setForgotPasswordModal }) {
	const dispatch = useDispatch();

	const loader = useSelector(isLoading)
	const errorMsg = useSelector(isError);

	const [loginData, setLoginData] = useState({})
	const [loginEye, setLoginEye] = useState(false)
	const [validationErrors, setValidationErrors] = useState({});
	const [allowLogin, setAllowLogin] = useState(false)

	useEffect(() => {
		setValidationErrors({})
	}, [])

	const onLoginAction = (event) => {
		event.preventDefault();
		setValidationErrors({})

		const errors = {};
		if (!loginData.username) {
			errors.username = "Username is required";
		}
		if (!loginData.password) {
			errors.password = "Password is required";
		}
		if (!allowLogin) {
			errors.allow = "Please check this box."
		}

		if (Object.keys(errors).length > 0) {
			setValidationErrors(errors);
			return;
		} else {
			event.stopPropagation();
			dispatch(login({ ...loginData, remember: true, device_type: 'Web', device_app_version: '1.0' }));
		}
	};

	return (
		<>
			<div className="modal_logo">
				<Link to="/">
					<img src={Logo} />
				</Link>
			</div>
			{/* <h5>Don&apos;t have an account? <span style={{ color: "#114BBC", fontWeight: '600', cursor: 'pointer' }} onClick={() => {
				setLoginModal(false)
				setRegisterModal(true)
			}}> Sign Up</span></h5> */}
			{/* <p style={{textAlign : 'left'}}>Log In</p>  */}
			<form onSubmit={onLoginAction}>
				<div className="modelContent">
					<ul className="form">
						<li>
							<div className="lbl">Username</div>
							<div className="val">
								<input type="text" className="input" placeholder="johndoe123" onChange={(e) => {
									setLoginData({
										...loginData,
										username: e.target.value
									})
								}}
									required
								/>
								<div className="inputLoader" style={{ display: "none" }}>
									<span className="spinLoader small"></span>
								</div>
								<div className="validation">
									<span style={{ display: validationErrors.username ? "" : "none" }}>
										{validationErrors.username}
									</span>
								</div>
							</div>
						</li>

						<li>
							<div className="lbl">Password</div>
							<div className="val">

								<div className="passwordField" >
									<input
										type={loginEye ? "text" : "password"} className="input imp"
										maxLength={16}
										placeholder="********"
										onChange={(e) => {
											setLoginData({
												...loginData,
												password: e.target.value
											})
										}}
										required
									/>
									<a href="javascript:;" id="" onClick={() => setLoginEye(!loginEye)}>
										{loginEye ? <span><img src={EyeActiveIcon} /></span> : <span>
											<img src={EyeIcon} /></span>}
									</a>
								</div>
								<div className="validation">
									<span style={{ display: validationErrors.password ? "block" : "none" }}>
										{validationErrors.password}
									</span>
								</div>

								<div className="signInAgree" style={{ marginTop: '20px' }} >
									<div className="customCheckbox">
										<input type="checkbox"
											value={allowLogin}
											onClick={(e) => {
												setAllowLogin(!allowLogin)
											}} /> <label> </label>
									</div>
									<div onClick={() => setLoginModal(false)}>
										I am atleast 18 years of age and I have read, accept and agree to <Link to="/footer/terms" className='theme-color'>Terms and Conditions</Link>
									</div>
								</div>
								<div className="validation">
									<span style={{ display: validationErrors.allow ? "block" : "none" }}>
										{validationErrors.allow}
									</span>
								</div>
							</div>
						</li>
					</ul>

					{/* <div className="bottomLink">
						<p>
							<a onClick={() => {
								setLoginModal(false)
								setRegisterModal(false)
								setForgotPasswordModal(true)
							}}>Forgot Password?</a>
						</p>
					</div> */}

					{errorMsg && <Alert
						message={errorMsg}
						variant="error"
					/>}

					<div className="submitBtnDiv">
						{loader ? <div className="inputLoader" style={{ textAlign: 'center' }}>
							<span className="spinLoader small"></span>
						</div> : <span className="submitBtn skyBlue" style={{ width: "100%", textAlign: "center" }}>
							<button type='submit' className="btn" onClick={onLoginAction}>Log In</button>
						</span>}
					</div>

					<div onClick={() => setLoginModal(false)} style={{ marginTop: '20px' }}>
						This site is protected by reCAPTCHA and the Google <Link to="/footer/privacy" className='theme-color'>Privacy Policy</Link> and <Link to="/footer/terms" className='theme-color'>Terms of Service</Link> apply.
					</div>

				</div>
			</form>

		</>
	)
}

export default Login