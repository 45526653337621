/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

function ModalPopup(props) {
    const [open, setOpen] = useState()

    useEffect(() => {
        if (props.show) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [props.show])


    return (
        <>
            <div className={open ? "overlay" : ''}></div>
            <div className={open ? "model" : 'hide'}>
                <div className={`modelWrapper ${props.className || ""}`}  style={{ maxWidth: props.size || "510px" }}>
                    <a href="javascript:;" className="close" onClick={() => {
                        setOpen(false)
                        props.onHide(false)
                    }} />
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default ModalPopup