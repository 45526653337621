/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Postdata } from '../../utils/api.service'
import HttpService from '../../utils/http.service'
import Alert from '../common/Alert'

function Forgot_Password({ setLoginModal, setRegisterModal, setForgotPasswordModal }) {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')


    const onForgotAction = (e) => {
        e.preventDefault()
        if (!email) {
            setErrorMessage('Please enter email...')
            setTimeout(() => {
                setErrorMessage('')
            }, 4000);
        } else {
            setLoading(true)
            Postdata(HttpService.forgotPasswordUrl, {
                email
            }).then((e) => {
                if (e.status == 200) {
                    setMessage(e.message)
                    setTimeout(() => {
                        setForgotPasswordModal(false)
                    }, 3000);
                } else {
                    setErrorMessage(e.message)
                }
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
        }
    }

    return (
        <>
            <h2>Forgot Password?</h2>
            <h5>Please enter registered email to recover your password.</h5>

            <form onSubmit={onForgotAction}>
                <div className="modelContent">
                    <ul className="form">
                        <li>
                            <div className="lbl">Email Address <span>*</span></div>
                            <div className="val">
                                <input type="text"
                                    className="input"
                                    placeholder="johndoe123"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />

                                <div className="inputLoader" style={{ display: "none" }}>
                                    <span className="spinLoader small"></span>
                                </div>
                                <div className="validation"><span style={{ display: "none" }}>Validation message in this span</span> </div>
                            </div>
                        </li>

                    </ul>

                    <div className="bottomLink">
                        <p>
                            <a onClick={() => {
                                setForgotPasswordModal(false)
                                setLoginModal(true)
                                setRegisterModal(false)
                            }}>Back to Sign In</a>
                        </p>
                    </div>

                    {errorMessage && <Alert
                        message={errorMessage}
                        variant="error"
                    />}

                    {message && <Alert
                        message={message}
                        variant="success"
                    />}

                    <div className="submitBtnDiv">
                        {loading ? <div className="inputLoader" style={{ textAlign: 'center' }}>
                            <span className="spinLoader small"></span>
                        </div> : <span className="submitBtn skyBlue" style={{ width: "100%", textAlign: "center" }}>
                            <button type='submit' className="btn" onClick={onForgotAction}>Submit</button>
                        </span>}
                    </div>
                </div>
            </form>
        </>
    )
}

export default Forgot_Password