import {
  combineReducers
} from "redux";
import auth from "./authReducer";
import home from "./homeReducer";
import call from "./callReducer";
import casino from "./casinoReducer";

export default combineReducers({
  auth,
  call,
  home,
  casino
});