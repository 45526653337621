export const isLoading = (state) => state.auth.loading;
export const isError = (state) => state.auth.error;
export const isAuthenticated = (state) => state.auth.isAuthenticated;
export const isMessage = (state) => state.auth.message;
export const isData = (state) => state.auth.data;
export const isNewLogin = (state) => state.auth.firstLogin;

export const isFriends = (state) => state.auth.refreshFriend;
export const isBecomeAgent = (state) => state.auth.becomeAgent;

export const isLoginRequired = (state) => state.auth.loginPopup;

export const isHomepage = (state) => state.auth.homepage;