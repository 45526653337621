import * as types from "../constants/constants";

let initialState = {
  data: [],
  error: "",
  message: "",
  refresh: "",
  isAuthenticated: false,
  loginPopup: "",
  becomeAgent: "",
  refreshFriend: "",
  loading: false,
  firstLogin : false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_DATA:
      return {
        ...state,
        error: "",
        message: "",
        isAuthenticated: false,
        loading: true
      };
    case types.LOGIN_DATA_SUCCESS:
      return {
        ...state,
        data: action.data.data,
        error: "",
        // message: action.data.message,
        message: "",
        isAuthenticated: true,
        loading: false,
        firstLogin : true
      };
    case types.LOGIN_UPDATE_SUCCESS:
      return {
        ...state,
        data: {
          user: action.data.data
        },
        error: "",
        message: action.data.message,
        isAuthenticated: true,
        loading: false
      };
    case types.LOGIN_DATA_FAIL:
      return {
        ...state,
        data: [],
        error: action.data,
        message: "",
        isAuthenticated: false,
        loading: false
      };
    case types.LOGIN_DATA_CLEAR:
      return {
        ...state,
        data: [],
        error: "",
        message: "",
        isAuthenticated: false,
        loading: false
      };
    case types.LOGIN_MSG_CLEAR:
      return {
        ...state,
        error: "",
        message: "",
      };
    case types.REFRESH_FRIEND:
      return {
        ...state,
        refreshFriend: Math.random()
      };
    case types.OPEN_LOGIN_POPUP:
      return {
        ...state,
        isAuthenticated: false,
        loginPopup: Math.random()
      };
    case types.BECOME_AGENT:
      return {
        ...state,
        becomeAgent: Math.random()
      };
    case types.LOGIN_MSG_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
        message: "",
      };
    case types.LOGIN_LOADER:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};