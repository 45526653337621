import React, { Suspense } from "react";
import lazyWithSync from "./lazyWithSync";
import { Route, Routes, useLocation } from "react-router-dom";

const Homepage = lazyWithSync(() => import("../components/pages/Homepage"));
const Homepage_After_Login = lazyWithSync(() => import("../components/pages/Homepage_After_Login"))

const Profile = lazyWithSync(() => import("../components/pages/Profile"))
const Change_Password = lazyWithSync(() => import("../components/pages/Change_Password"))
const Bet_Stake_Setting = lazyWithSync(() => import("../components/pages/Bet_Stake_Setting"))
const Account_Statement = lazyWithSync(() => import("../components/pages/Account_Statement"))
const Profit_Loss = lazyWithSync(() => import("../components/pages/Profit_Loss"))
const Bet_History = lazyWithSync(() => import("../components/pages/Bet_History"))
const Casino_Bet_History = lazyWithSync(() => import("../components/pages/Casino_Bet_History"))
const Live_Bet = lazyWithSync(() => import("../components/pages/Live_Bet"))
const Comparitive_Analysis = lazyWithSync(() => import("../components/pages/Comparitive_Analysis"))
const Terms_Conditions = lazyWithSync(() => import("../components/pages/Terms_Conditions"))
const Favourites = lazyWithSync(() => import('../components/pages/Favourites'))
const Transaction_History = lazyWithSync(() => import("../components/pages/Transaction_History"))
const UnSettled_Bets = lazyWithSync(() => import("../components/pages/UnSettled_Bets"))


const In_Play = lazyWithSync(() => import("../components/pages/Home/In_Play"))
const Home_Cricket = lazyWithSync(() => import("../components/pages/Home/Cricket"))
const Home_Hockey = lazyWithSync(() => import("../components/pages/Home/Hockey"))
const Home_Soccer = lazyWithSync(() => import("../components/pages/Home/Soccer"))
const Home_Tennis = lazyWithSync(() => import("../components/pages/Home/Tennis"))
const Home_Election = lazyWithSync(() => import("../components/pages/Home/Election"))
const Home_Horse_Racing = lazyWithSync(() => import("../components/pages/Home/Horse_Racing"))
const Daily_Rewards = lazyWithSync(() => import("../components/pages/Daily_Rewards"))

const Match_Details = lazyWithSync(() => import("../components/pages/Match_Details"))

const Private_Bet = lazyWithSync(() => import("../components/pages/Private_Bet"))
const Create_Contest = lazyWithSync(() => import("../components/pages/Contest/Create_Contest"))
const Create_Weather_Contest = lazyWithSync(() => import("../components/pages/Contest/Create_Weather_Contest"))
const Join_Contest = lazyWithSync(() => import("../components/pages/Contest/Join_Contest"))

const Error404 = lazyWithSync(() => import("../components/common/404"));
const Footer_Page = lazyWithSync(() => import("../components/pages/Footer_Page"))
const Casino_Panel = lazyWithSync(() => import("../components/pages/Casino/CasinoIframe"))
const Casino_Page = lazyWithSync(() => import("../components/pages/Casino/Casino_Page"))

const Dream_Casino_Frame = lazyWithSync(() => import("../components/pages/Dream_Casino/Casino_Frame"))
const Aura_Casino_Frame = lazyWithSync(() => import("../components/pages/Aura_Casino"))

import PrivateRoute from "./PrivateRoute";
// import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
// import ScrollToTop from "../components/common/ScrollToTop";
import Loader from "../components/common/Loader";
import Sidebar from "../components/common/Sidebar";
import ScrollToTop from "../components/common/ScrollToTop";

import { useSelector } from "react-redux";
import { isAuthenticated } from "../redux/selectors/authSelector";


function DefaultRoutes() {
  const Authenticated = useSelector(isAuthenticated)
  const location = useLocation()
  return (
    <>
      <section className={Authenticated && location.pathname !== "/casino-game" ? "pageContent hasAnnouncement" : "page hasAnnouncement"} style={{ padding: '0px' }} >
        {location.pathname !== "/casino-game" && <Header />}
        {/* <Header/> */}
        {Authenticated && location.pathname !== "/casino-game" ? <Sidebar /> : ''}
        <ScrollToTop />
        <Suspense fallback={<Loader fullscreen="loader_fullscreen" />}>
          <Routes>
            <Route exact path="/" element={Authenticated ? <Home_Cricket /> : <Homepage />} />
            <Route exact path="/:id" element={Authenticated ? <Homepage_After_Login /> : <Homepage />} />
            <Route exact path="/profile" element={<PrivateRoute Component={Profile} />} />
            <Route exact path="/home" element={<PrivateRoute Component={Homepage_After_Login} />} />
            <Route exact path="/change-password" element={<PrivateRoute Component={Change_Password} />} />
            <Route exact path="/bet-stake-setting" element={<PrivateRoute Component={Bet_Stake_Setting} />} />
            <Route exact path="/account-statement" element={<PrivateRoute Component={Account_Statement} />} />
            <Route exact path="/profit-loss" element={<PrivateRoute Component={Profit_Loss} />} />
            <Route exact path="/bet-history" element={<PrivateRoute Component={Bet_History} />} />
            <Route exact path="/casino-bet-history" element={<PrivateRoute Component={Casino_Bet_History} />} />
            <Route exact path="/live-bet" element={<PrivateRoute Component={Live_Bet} />} />
            <Route exact path="/comparitive-analysis" element={<PrivateRoute Component={Comparitive_Analysis} />} />
            <Route exact path="/terms-condition" element={<PrivateRoute Component={Terms_Conditions} />} />
            <Route exact path="/favourites" element={<PrivateRoute Component={Favourites} />} />
            <Route exact path="/unsettled-bets" element={<PrivateRoute Component={UnSettled_Bets} />} />

            <Route exact path="/private-bet" element={<PrivateRoute Component={Private_Bet} />} />
            <Route exact path="/private-bet/create-contest" element={<PrivateRoute Component={Create_Contest} />} />
            <Route exact path="/private-bet/create-weather-contest" element={<PrivateRoute Component={Create_Weather_Contest} />} />
            <Route exact path="/join-group" element={<PrivateRoute Component={Join_Contest} />} />
            <Route exact path="/join-group/:id" element={<PrivateRoute Component={Join_Contest} />} />

            <Route exact path="/transaction-history" element={<PrivateRoute Component={Transaction_History} />} />

            <Route exact path="/in-play" element={<PrivateRoute Component={In_Play} />} />
            <Route exact path="/cricket" element={<PrivateRoute Component={Home_Cricket} />} />
            <Route exact path="/hockey" element={<PrivateRoute Component={Home_Hockey} />} />
            <Route exact path="/soccer" element={<PrivateRoute Component={Home_Soccer} />} />
            <Route exact path="/tennis" element={<PrivateRoute Component={Home_Tennis} />} />
            <Route exact path="/election" element={<PrivateRoute Component={Home_Election} />} />
            <Route exact path="/horse-racing" element={<PrivateRoute Component={Home_Horse_Racing} />} />
            <Route exact path="/daily-rewards" element={<PrivateRoute Component={Daily_Rewards} />} />
            <Route exact path="/match-details/:event_id" element={<PrivateRoute Component={Match_Details} />} />
            <Route exact path="/casino-game" element={<PrivateRoute Component={Casino_Panel} />} />
            <Route exact path="/casino" element={<PrivateRoute Component={Casino_Page} />} />
            <Route exact path="/dream-casino-game" element={<PrivateRoute Component={Dream_Casino_Frame} />} />
            <Route exact path="/aura-casino-game" element={<PrivateRoute Component={Aura_Casino_Frame} />} />
            <Route exact path="/footer/:link" element={<Footer_Page />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Suspense>
      </section>
      {/* {location.pathname !== "/casino-game" && <Footer />} */}
    </>
  );
}

export default DefaultRoutes;
