/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { isData } from '../../redux/selectors/authSelector'

function Balance({ setBalanceModal, setAddMoneyModal, setWithDrawModal }) {
    const profileData = useSelector(isData)
    const userData = profileData.user
    
    return (
        <>
            <h2>Balance Details</h2>
            <div className="modelContent">
                <div className="grid2">
                    <div className="box wallet">
                        <label>Deposit Wallet</label>
                        <span>{userData.usable_coin_balance}</span>
                    </div>

                    <div className="box wallet">
                        <label>Win Wallet</label>
                        <span>{userData.win_wallet}</span>
                    </div>

                    {/* <div className="box wallet">
                        <label>Bonus Wallet</label>
                        <span>{userData.bonus_wallet}</span>
                    </div> */}
                </div>

                <div className="grid2 submitBtnDiv">
                    <span className="submitBtn yellow" onClick={() => {
                        setBalanceModal(false)
                        setWithDrawModal(false)
                        setAddMoneyModal(true)
                    }}>
                        <button className='btn'>Add Money</button>
                    </span>

                    <span className="submitBtn skyBlue" onClick={() => {
                        setBalanceModal(false)
                        setAddMoneyModal(false)
                        setWithDrawModal(true)
                    }}>
                        <button className='btn'>Withdraw Money</button>
                    </span>
                </div>

                <div className="bottomLink"><Link onClick={() => {
                    setBalanceModal(false)
                }} to="/account-statement">Transaction History</Link></div>
            </div>
        </>
    )
}

export default Balance