const slug = 'app'
const baseUrl = process.env.REACT_APP_API_URL + slug

const HttpService = {
  HomeUrl: baseUrl + '/home',
  getPlayersData: baseUrl + '/home/leaderboard/player_data',
  SignupAvatar: baseUrl + '/avatar',
  Leaderboard: baseUrl + '/leaderboard',
  LeaderboardFriends: baseUrl + '/leaderboard/friends',
  LeaderboardGameTypes: baseUrl + '/leaderboard/get_game_types',

  loginUrl: baseUrl + '/login',
  passwordUrl: baseUrl + '/update_password',
  logoutUrl: baseUrl + '/logout',
  registerUrl: baseUrl + '/signup',
  countryCode: baseUrl + '/get-country-code',
  forgotPasswordUrl: baseUrl + '/forgot_password',
  forgotUsernameUrl: baseUrl + '/forgot_username',
  contactUsUrl: baseUrl + '/contact_us',
  becomeAgentUrl: baseUrl + '/become_an_agent',
  validateResetLinkUrl: baseUrl + '/validate_reset_link',
  resetPasswordWithLinkUrl: baseUrl + '/reset_password_with_link',
  upcomingMatch: baseUrl + '/get-upcomming-cricket-event',

  verifyTokenUrl: baseUrl + '/verify-token',
  updateProfileUrl: baseUrl + '/user/update_profile',

  updateUserKyc: baseUrl + '/user/update_kyc',
  getUserKyc: baseUrl + '/user/get_kyc',

  userSendOtp: baseUrl + '/user/send_otp',
  userVerifyOtp: baseUrl + '/user/verify_otp',
  userProfitLoss: baseUrl + '/user/profit-loss',
  userBetHistory: baseUrl + '/user/place-bet-history',
  userUnSettledBets: baseUrl + '/user/get-unsettled-bets',
  userProfile: baseUrl + '/user/get_profile',

  socialFetchUsers: baseUrl + '/social/fetch_users',
  socialFetchFriends: baseUrl + '/social/fetch_friends',
  socialFetchBlockedUsers: baseUrl + '/social/fetch_blocked_users',
  socialFriendRequest: baseUrl + '/social/change_friend_status',
  socialBlockRequest: baseUrl + '/social/change_blocked_status',

  notificationList: baseUrl + '/notification/list',
  notificationStatus: baseUrl + '/notification/change_status',

  pageView: baseUrl + '/page',
  appLinks: baseUrl + '/get_app_links',

  bankdetailsAgent: baseUrl + '/bank/agent',

  bankdetailsList: baseUrl + '/bank/list',
  bankdetailsSave: baseUrl + '/bank/store',
  bankdetailsDelete: baseUrl + '/bank/delete',

  Game_category: baseUrl + '/games/category',
  Game_list: baseUrl + '/games/list',
  Game_view: baseUrl + '/games/view',
  Game_subscribe: baseUrl + '/games/subscribe',

  Gameplay_turbo: baseUrl + '/get_turbogame_link',
  Gameplay_arrowwood: baseUrl + '/get_arrow_wood_link',
  Gameplay_evenbet: baseUrl + '/get_evenbet_link',

  Getprofile: baseUrl + '/user/get_profile',
  AgoraToken: baseUrl + '/get_agora_access_token',
  Chat_uploader: baseUrl + '/chat/uploads',
  Chat_create_group: baseUrl + '/chat/create_group',
  Chat_group_info: baseUrl + '/chat/group_info',
  Chat_group_user_list: baseUrl + '/chat/group_user_list',
  Chat_group_edit: baseUrl + '/chat/group_edit',


  Coins_add: baseUrl + '/user/add_coins',
  Coins_withdraw: baseUrl + '/user/withdrawal_request',
  Coins_history: baseUrl + '/user/coin_history',

  Game_history: baseUrl + '/user/game_history',
  Promocodes: baseUrl + '/promocodes',

  Feed_store: baseUrl + '/feed/store',
  Feed_reaction: baseUrl + '/feed/reaction',
  Feed_home: baseUrl + '/feed/home',
  Feed_delete: baseUrl + '/feed/delete',

  Comment_list: baseUrl + '/feed/comment_list',
  Comment_store: baseUrl + '/feed/comment',

  Rewards_daily_check: baseUrl + '/user/daily-check-in',
  Rewards_check_in_count: baseUrl + '/user/get-daily-check-in',

  Cricket_matches: baseUrl + '/cricket/matches',
  Cricket_match_details: baseUrl + '/cricket/match_details',
  Cricket_match_contest: baseUrl + '/cricket/match_contest',
  Cricket_contest_details: baseUrl + '/cricket/contest_details',
  Cricket_contest_join: baseUrl + '/cricket/join_contest',
  Cricket_team: baseUrl + '/cricket/contest_team',
  Cricket_update_team: baseUrl + '/cricket/contest_update_team',
  Cricket_update_captain: baseUrl + '/cricket/contest_update_captain',
  Cricket_my_contest: baseUrl + '/cricket/my_contest',

  Cricket_my_contests: baseUrl + '/cricket/my_contests',
  Cricket_leaderboard_details: baseUrl + '/cricket/leaderboard',
  Cricket_get_live_score: baseUrl + '/cricket/get_live_score',

  Football_my_contests: baseUrl + '/football/my_contests',
  Football_matches: baseUrl + '/football/matches',

  Spinwheel_get_list: baseUrl + '/spin_Wheel/list',
  Spinwheel_get_reward: baseUrl + '/spin_wheel/get_reward',

  Vip_list: baseUrl + '/vip_user/list',
  Vip_join_membership: baseUrl + '/vip_user/join_membership',

  Bet_Event_List: baseUrl + '/event_list',
  Bet_Event_List_Dream: baseUrl + '/event_list_dream',
  Bet_Event_Details: baseUrl + '/get_event_details',
  Bet_Event_Details_Dream: baseUrl + '/get_event_details_dream',
  Place_Bet: baseUrl + '/user/place_bet',
  Place_Bet_New: baseUrl + '/user/place_bet2',
  Bet_Min_Max: baseUrl + '/user/get-min-max',
  Get_User_Match_Bet: baseUrl + '/betting/get-user-match-bet',

  Comparative_Analysis: baseUrl + '/betting/comparative-analysis',
  In_play: baseUrl + '/inplaygames',

  Private_Bet_Game_Type: baseUrl + '/games/category?type=betting',
  Private_Bet_Upcoming_Game: baseUrl + '/betting/games',
  Private_Bet_Questions: baseUrl + '/betting/questions',
  Private_Bet_Create: baseUrl + '/betting/group/sport/create',
  Weather_Bet_Create: baseUrl + '/betting/group/weather/create',
  Get_Private_Bet_Info: baseUrl + '/betting/group/show',
  Join_Bet: baseUrl + '//betting/group/join-group',

  Get_Countries: baseUrl + '/get-countries',
  Get_States: baseUrl + '/get-states',
  Get_Cities: baseUrl + '/get-cities',

  Bet_Stake_Setting: baseUrl + '/user/get_stake',
  Add_Bet_Stake_Setting: baseUrl + '/user/add_stake',
  Delete_Bet_Stake_Setting: baseUrl + '/user/delete_stake',

  //digitain game url
  Get_Casino_Url: baseUrl + '/get_digitain_link',
  Get_Casino_Category: baseUrl + "/casino_category/getall",
  Get_Casino_Provider: baseUrl + "/casino_provider/getall",
  Get_Casino_Games: baseUrl + "/casino_games/getByCategoryType_Id",
  Get_Casino_Bets: baseUrl + "/casino_bets/getByUser",

  Get_Dream_Casino_Games : baseUrl + "/dream_casino/get-games",

  Get_Dream_Casino_Url: baseUrl + '/dream_casino/get-game-link',
  Get_Aura_Casino_Url: baseUrl + '/aura_casino/get_url',

  Get_Casino_FavGames: baseUrl + "/user/get-favourite-casino_game",
  Add_CasinoGames_fav: baseUrl + "/user/set-favourite-casino_game",
  Remove_CasinoGames_fav: baseUrl + "/user/remove-favourite-casino_game",

  Get_Event_Notification : baseUrl + "/get-notification-event"


};

export default HttpService;