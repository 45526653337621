/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginMessage } from '../../redux/actions/authActions';

function Alert(props) {
    const [show, setshow] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        // window.scrollTo({
        //     top: 0,
        //     left: 0,
        //     behavior: 'smooth'
        // });

        const intervalId = setTimeout(() => {
            setshow(false);
            // setshow(true);
        }, 4000);
        return () => clearTimeout(intervalId);
    }, []);

    useEffect(() => {
        if (!show) {
            dispatch(loginMessage());
        }
    }, [show]);

    useEffect(() => {
        if (props.message) {
            setshow(true)
            const intervalId = setTimeout(() => {
                setshow(false);
                // setshow(true);
            }, 4000);
            return () => clearTimeout(intervalId);
        }
    }, [props.message]);

    return (
        <>
            {show ? <div
                onClick={() => setshow(false)}
                {...props}
                style={{ cursor: 'pointer' }}
            >
                {props.variant === 'error' && <span className="alert alert-error">{props.message}</span>}
                {props.variant === 'success' && <span className="alert alert-success">
                    {props.message}</span>}
            </div> : ''}


        </>
    );
}

export default Alert;