/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import { Postdata } from '../../utils/api.service'
import HttpService from '../../utils/http.service'
import Loader from '../common/Loader'

import { ConvertDate, ConvertOnlyTime } from '../common/Helper'

function Live_Bets({ show }) {
    const [loading, isLoading] = useState(false)
    const [data, setData] = useState([])
    const [page, setPage] = useState(10)
    const [scrollPosition, setScrollPosition] = useState(0);
    const [stopCall, setStopCall] = useState(false)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        isLoading(true)
        Postdata(HttpService.Get_User_Match_Bet, {
            perpage: 10,
            page: 1
        }).then((e) => {
            if (e.status == 200) {
                setData(e.data?.records)
                setTotal(e.data.pagiantion?.total)
            }
            isLoading(false)
        }).catch((err) => {
            console.log(err)
            isLoading(false)
        });
    }, [show])

    const fetchData = () => {
        Postdata(HttpService.Get_User_Match_Bet, {
            perpage: page,
            page: 1
        }).then((e) => {
            if (e.status == 200) {
                if (e.data.records.length == e.data.pagiantion.total) {
                    setStopCall(true)
                }
                setData(e.data?.records)
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (scrollPosition + window.innerHeight >= document.documentElement?.scrollHeight - document.getElementById("footer_bottom")?.scrollHeight) {
            {
                if (!stopCall) {
                    if (page + 10 < total) {
                        setPage(page + 10)
                        fetchData()
                    } else {
                        setPage(total)
                        fetchData()
                    }
                }
            }
        }
    }, [scrollPosition]);

    return (
        <>
            <h2>Live Bets</h2>
                <section className='modalTransactionReport'>
                    <div className="tableResponsive">
                        <div className="tabelUL" style={{ marginTop: '10px' }}>
                            <ul>
                                <li className="gameTitle">
                                    <div className="grid8">
                                        {/* <label>ID</label> */}
                                        <label>Description</label>
                                        {/* <label>Side</label> */}
                                        <label>Odds</label>
                                        <label>Stake</label>
                                        {/* <label>Date</label> */}
                                        <label>P&L</label>
                                        {/* <label>Status</label> */}
                                    </div>
                                </li>

                                {loading ? <Loader /> :
                                    <>
                                        {data.length ?
                                            (
                                                data.map((val, index) => {
                                                    return (
                                                        <li className="gameRow" key={val._id}>
                                                            <div className="grid8">
                                                                {/* <label>{index + 1}</label> */}
                                                                <label>{val.description} <br/> {val.bet_type}</label>
                                                                {/* <label>{val.bet_type}</label> */}
                                                                <label>{val.rate}</label>
                                                                <label>{val.stake}</label>
                                                                {/* <label>{ConvertDate(val.createdAt)} {ConvertOnlyTime(val.createdAt)}</label> */}
                                                                <label>{val.temp_profitloss}</label>
                                                                {/* <label className={val.result == 'pending' ? '' : (val.isWin ? 'green' : 'red')}>{val.result == 'pending' ? 'Pending' : (val.isWin ? 'Win' : 'Loss')}</label> */}
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            )
                                            :
                                            <li className="gameRow">
                                                <div className="grid8">
                                                    {/* <label>-</label> */}
                                                    <label>No Records Found</label>
                                                    <label>-</label>
                                                    <label>-</label>
                                                    <label>-</label>
                                                    {/* <label>-</label> */}
                                                    {/* <label>-</label> */}
                                                    {/* <label>-</label> */}
                                                </div>
                                            </li>
                                        }
                                    </>
                                }


                            </ul>
                        </div>
                    </div>
                </section>
        </>
    )
}

export default Live_Bets