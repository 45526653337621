/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { isAuthenticated } from "../redux/selectors/authSelector";
import { verifyToken } from "../redux/actions/authActions";
import Loader from '../components/common/Loader';
import { useEffect } from 'react';
import { isData } from '../redux/selectors/authSelector';

const PrivateRoute = ({ Component, size }) => {
  const dispatch = useDispatch();
  const auth = useSelector(isAuthenticated);
  const userData = useSelector(isData)
  const navigate = useNavigate()

  useEffect(() => {

    let timer = 360000;
    if (window.location.pathname.includes('/dream-casino-game')) timer = 2000;
    if (window.location.pathname.includes('/aura-casino-game')) timer = 2000;
    // if (window.location.pathname.includes('/match-details')) timer = 1500;
    const intervalId = setInterval(() => {
      dispatch(verifyToken());
    }, timer);
    return () => clearInterval(intervalId);
  }, [window.location.pathname]);

  useEffect(() => {
    if (userData.user?.change_password) {
      navigate('/change-password')
    }
  }, [window.location.pathname])

  if (!auth && window.localStorage.getItem('authToken')) {
    return <Loader fullscreen="loader_fullscreen" />;
  }

  return auth ?
    <>
      <div>
        <Component />
      </div>
    </>
    : <Navigate to="/" state={{ login: true }} />
}

export default PrivateRoute;
