export const LOGIN_DATA = "LOGIN_DATA";
export const LOGIN_DATA_SUCCESS = "LOGIN_DATA_SUCCESS";
export const LOGIN_DATA_FAIL = "LOGIN_DATA_FAIL";
export const LOGIN_DATA_CLEAR = "LOGIN_DATA_CLEAR";
export const LOGIN_LOADER = "LOGIN_LOADER";
export const LOGIN_MSG_CLEAR = "LOGIN_MSG_CLEAR";
export const LOGIN_UPDATE_SUCCESS = "LOGIN_UPDATE_SUCCESS";
export const LOGIN_MSG_ERROR = "LOGIN_MSG_ERROR";
export const DONT_SHOW_TERMS = "LOGIN_MSG_ERROR";

export const REFRESH_FRIEND = "REFRESH_FRIEND";
export const BECOME_AGENT = "BECOME_AGENT";
export const OPEN_LOGIN_POPUP = "OPEN_LOGIN_POPUP";

export const HOMEPAGE = "HOMEPAGE";
export const HOMEPAGEERROR = "HOMEPAGEERROR";
export const ONLINEUSERS = "ONLINEUSERS";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const SOCKET = "SOCKET";
export const ADDPLAYERSDATA = "ADDPLAYERSDATA";

export const CREATECALL = "CREATECALL";
export const CLOSECALL = "CLOSECALL";
export const IMCOMINGCALL = "IMCOMINGCALL";

export const CHATLISTLOADING = "CHATLISTLOADING";
export const CHATLISTUPDATE = "CHATLISTUPDATE";
export const CHATLIST = "CHATLIST";

export const SET_CASINO_CATEGORY = "SET_CASINO_CATEGORY";
export const SET_CASINO_PROVIDER = "SET_CASINO_PROVIDER";
export const SET_CASINO_GAMES = "SET_CASINO_GAMES";
export const SET_CASINO_FAV_GAMES = "SET_CASINO_FAV_GAMES";

